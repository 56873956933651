<template>
  <VApp id="HolyGrailLayout">
    <RouterView
      name="nav"
    />

    <RouterView
      name="aside-left"
    />

    <VMain :class="withMenu">
      <RouterView />
      <RouterView name="content-footer" />
    </VMain>

    <RouterView name="aside-right" />

    <RouterView name="footer" />
  </VApp>
</template>

<script>
export default {
  name: 'HolyGrailLayout',

  data() {
    return {
    };
  },
  computed: {
    withMenu() {
      if (this.$vuetify.application.left) {
        return {
          'tt-main__xl_menu': this.$vuetify.breakpoint.xl,
          'tt-main__lg_menu': this.$vuetify.breakpoint.lg,
          'tt-main__md_menu': this.$vuetify.breakpoint.md,
          'tt-main__sm_menu': this.$vuetify.breakpoint.sm,
        };
      }
      return {};
    },
  },
};
</script>
